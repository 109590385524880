import { createRouter, createWebHashHistory } from 'vue-router'

import index from '../components/index.vue'

import UserAgreement from '../components/UserAgreement.vue'

import PrivacyAgreement from '../components/PrivacyAgreement.vue'

const routes = [

   // 在此处配置路由;
  { path: '/', component: index },
   
  { path: '/userAgreement', component: UserAgreement },
   
  { path: '/privacyAgreement', component: PrivacyAgreement }

]

// 在router4中使用createRouter替代了new VueRouter;

const router = createRouter({ 

  history: createWebHashHistory(),

  routes

  // 在此处引入所有页面的路由配置数组routes;

})

export default router   

// 导出包含着所有路由配置的router对象,

// 在main.js中注入后供全局使用;