<template>
	<!-- 用户协议 -->
	<div class="userAgreement">
		<div class="back" @click="goBack">/ 返回上一页</div>
		<div class="content">
			<iframe
				src="http://activity.junmiaokeji.top/#/protocolPage?type=UserAgreement"
				frameborder="0"
				width="100%"
				height="100%"
			></iframe>
		</div>
	</div>
</template>

<script>
export default {
	name: 'UserAgreement',
	methods: {
		goBack() {
			this.$router.go(-1)
		}
	}
}
</script>

<style scoped lang="scss">
.userAgreement {
	width: 100%;
	font-size: 14px;
	box-sizing: border-box;
	.back {
		width: 120px;
		font-size: 14px;
		cursor: pointer;
		padding: 20px;
	}
	.content {
		height: calc(100vh - 80px);
		width: 100%;
	}
}
</style>
